import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/Layout/Layout";
import PageFooter from "../components/Layout/PageFooter";

const PrintTemplate = ({ data, location, pageContext }) => {
  const { contentfulMetaInformation: meta, contentfulPrint: print } = data;
  const photo = print && print.photo;
  const seriesPreview = print && print.seriesPreview;
  return (
    <Layout location={location}>
      <Helmet>
        <title>{`Details`}</title>
      </Helmet>

      <div className="simple-title">
        <h1>{print.title}</h1>
      </div>
      <div className="container">
        <div
          className="image-detail-wrapper"
          style={{ textAlign: "center", margin: "20px 0" }}
        >
          <img src={`${photo.file.url}`} style={{ maxWidth: "100%" }} />

          <div className="actions-wrapper" style={{ margin: "40px 0" }}>
            {print.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: print.description.childMarkdownRemark.html,
                }}
              />
            )}

            {seriesPreview && (
              <img
                src={`${seriesPreview.file.url}`}
                style={{ maxWidth: "300px" }}
              />
            )}

            {meta.orderText && (
              <div
                style={{ margin: "20px 0" }}
                dangerouslySetInnerHTML={{
                  __html: meta.orderText.childMarkdownRemark.html,
                }}
              />
            )}

            <a href={`mailto:${meta.emailOrders}`}>{meta.ordersButton}</a>
          </div>
        </div>
      </div>
      <PageFooter />
    </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    contentfulMetaInformation(metaId: { eq: "Basic" }) {
      emailOrders
      ordersButton
      orderText {
        childMarkdownRemark {
          html
        }
      }
    }
    contentfulPrint(id: { eq: $id }) {
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      photo {
        file {
          url
        }
      }
      seriesPreview {
        file {
          url
        }
      }
    }
  }
`;

export default PrintTemplate;
