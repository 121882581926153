import React from "react";
import { Row, Col } from "antd";
import "./styles.less";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer className="page-footer">
      <div className="container core">
        <Row style={{ textAlign: "center", margin: "50px 0 0" }}>
          <Col xs={24}>
            ©Lea Wormsbach {new Date().getFullYear()}
            {" | "}
            <Link to="/imprint">Imprint</Link>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
